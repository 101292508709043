<template>
  <div>
    <LiefengModal
        title="新增系统"
        :value="controlForm.formFlag"
        :fullscreen="true"
        :width="600"
        :height="600"
        @input="closeModal"
    >
      <template v-slot:contentarea>
        <div class="container">
          <div class="menu">
            <Menu theme="light" :active-name="controlForm.showMenu" @on-select="activeMenu">
              <MenuItem name="baseInfo">
                <Icon type="ios-information-circle" />系统配置信息
              </MenuItem>
              <MenuItem name="authInfo">
                <Icon type="ios-settings" />安全设置
              </MenuItem>
            </Menu>
          </div>
          <div class="content">
            <Form
            :model="formData"
            :label-colon="true"
            :label-width="160"
            v-show="controlForm.showMenu == 'baseInfo'"
            :rules="formDataValidate"
            ref="form"
        >

          <FormItem label="系统名称" prop="appName">
            <Input style="width:300px" v-model.trim="formData.appName" :maxlength="50"
                   placeholder="请填写saas系统名称"></Input>
          </FormItem>
          <FormItem label="系统编码" prop="appCode">
            <Input style="width:300px" v-model.trim="formData.appCode" :maxlength="50"
                   placeholder="请填写saas系统编码"></Input>
          </FormItem>
          <FormItem label="父级系统">
            <Cascader :data="controlForm.systemTree" change-on-select v-model="controlForm.parentId" style="width: 200px;margin-right:10px"></Cascader>
          </FormItem>
          <FormItem label="系统分类" prop="systemType">
            <Select v-model="formData.systemType" style="width:200px;text-align:left;margin-right:10px" placeholder="请选择系统分类">
              <Option v-for="(item,index) in controlForm.systemTypeList" :value="item.dictValue" :key="index">{{
                  item.dictName
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem>
              <span slot="label" >系统首页地址:</span>
              <Input style="width:300px" v-model.trim="formData.paraJson.systemIndex" :maxlength="50"
                     placeholder="请填写首页地址"></Input>
            </FormItem>
          <FormItem label="客户端类型" prop="clientType">
            <Select v-model="formData.clientType" style="width:200px;text-align:left;margin-right:10px" placeholder="请选择客户端类型">
              <Option v-for="(item,index) in controlForm.clientTypeList" :value="item.dictValue" :key="index">{{
                  item.dictName
                }}
              </Option>
            </Select>
          </FormItem>
          
            
           

            <FormItem style="display:inline-block;width:260px"> 
              <span slot="label">Logo上传:</span>
              <LiefengUpload ref="LiefengUploadLogo" v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :defaultList='controlForm.defaultListLogo' :showView="true"></LiefengUpload>
            </FormItem>
            <span style="color: #c4c4c4;line-height:80px">说明：72 x 72px</span>
            <br>
            <FormItem style="display:inline-block;width:260px"> 
              <span slot="label">形象图片:</span>
              <LiefengUpload ref="LiefengUploadImage" v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :defaultList='controlForm.defaultListImage' :showView="true"></LiefengUpload>
            </FormItem>

            <span style="color: #c4c4c4;line-height:80px">说明：500 x 300px</span>
            <br>
            <FormItem label="顶部菜单背景颜色">
              <Input style="width:300px" v-model.trim="formData.paraJson.topBackColor" :maxlength="50"
                     placeholder="如 #ffffff"></Input>
            </FormItem>
            <FormItem label="顶部菜单字体颜色">
              <Input style="width:300px" v-model.trim="formData.paraJson.topWordColor" :maxlength="50"
                     placeholder="如 #ffffff"></Input>
            </FormItem>
            <FormItem label="顶部菜单选中背景颜色">
              <Input style="width:300px" v-model.trim="formData.paraJson.topChooseBackColor" :maxlength="50"
                     placeholder="如 #ffffff"></Input>
            </FormItem>
            <FormItem label="顶部菜单选中字体颜色">
              <Input style="width:300px" v-model.trim="formData.paraJson.topChooseWordColor" :maxlength="50"
                     placeholder="如 #ffffff"></Input>
            </FormItem>
            <FormItem label="左侧菜单背景颜色">
              <Input style="width:300px" v-model.trim="formData.paraJson.leftBackColor" :maxlength="50"
                     placeholder="如 #ffffff"></Input>
            </FormItem>
            <FormItem label="左侧菜单字体颜色">
              <Input style="width:300px" v-model.trim="formData.paraJson.leftWordColor" :maxlength="50"
                     placeholder="如 #ffffff"></Input>
            </FormItem>
            <FormItem label="左侧菜单选中背景颜色">
              <Input style="width:300px" v-model.trim="formData.paraJson.leftChooseBackColor" :maxlength="50"
                     placeholder="如 #ffffff"></Input>
            </FormItem>
            <FormItem label="左侧菜单选中字体颜色">
              <Input style="width:300px" v-model.trim="formData.paraJson.leftChooseWordColor" :maxlength="50"
                     placeholder="如 #ffffff"></Input>
            </FormItem>
          <FormItem label="系统说明" prop="systemDesc">
            <Input style="width:300px" v-model.trim="formData.systemDesc" :maxlength="50"
                   placeholder="请填写系统说明"></Input>
          </FormItem>
          <FormItem label="备注" prop="remark">
            <Input style="width:300px" v-model.trim="formData.remark" :maxlength="50"
                   placeholder="请填写备注"></Input>
          </FormItem>

        </Form>

        <Form
            :model="formData"
            :label-colon="true"
            :label-width="160"
            v-show="controlForm.showMenu == 'authInfo'"
            >
              <div class="securityDiv">
                <p><span>登录设置</span></p>
              </div><br>
              <FormItem label="单一登录">
              <Select v-model="formData.paraJson.singleLogin" style="width:200px;" placeholder="单一登录">
                <Option value="0">无限制</Option>
                <Option value="1">后登录踢出前登录</Option>
              </Select>
              </FormItem>
              <FormItem label="超时退出">
                <Input v-model.trim="formData.paraJson.overtimeSignOut" style="width:100px"><span slot="append">分钟</span> </Input>
              </FormItem>
              <div class="securityDiv">
                <p><span>访问设置</span></p>
              </div><br>
              <FormItem label="开启验证">
                <i-switch true-value="1" false-value="0" v-model="formData.paraJson.visitVerification"/>
              </FormItem>
              <FormItem label="允许访问IP">
                <Input type="textarea" rows="4" v-model.trim="formData.paraJson.allowIps" style="width:300px" placeholder="多个ip请换行填写"></Input>
              </FormItem>
            </Form>
          </div>
        </div>

        
      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="closeModal(false)">取消</Button>
        <Button type="primary" @click="saveData">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import LiefengUpload from '@/components/LiefengUpload';

export default {
  components: {LiefengModal,LiefengUpload},

  data() {
    return {
      showImage : true,
      controlForm: {
        defaultListLogo:[],
        defaultListImage:[],
        showMenu: 'baseInfo',
        systemTree:[],
        id:'1',
        parentId:[],
        formFlag: false,
        timer:'',
        systemTypeList: [{'dictName': '真实的', 'dictValue': '1'}, {'dictName': '虚拟的', 'dictValue': '2'}, {'dictName': '首页导航', 'dictValue': '3'}],
        clientTypeList: [{'dictName': 'PC', 'dictValue': 'PC'}, {'dictName': '微信', 'dictValue': 'WX_APPLET'}, {'dictName': 'IOS', 'dictValue': 'IOS'}, {'dictName': 'APP', 'dictValue': 'APP'},{'dictName': 'ANDROID', 'dictValue': 'ANDROID'}],
        fatherSystemList: '',
      },
      formData: {
        parentId: '0',
        systemType: '',
        clientType: '',
        appName: '',
        appCode: '',
        systemDesc: '',
        remark: '',
        paraJson: {
          appName:'',
          appCode:'',
          systemIndex: '',
          systemLogo: '',
          systemImage: '',
          topBackColor: '',
          topWordColor: '',
          topChooseBackColor: '',
          topChooseWordColor: '',
          leftBackColor: '',
          leftWordColor: '',
          leftChooseBackColor: '',
          leftChooseWordColor: '',
          singleLogin: '0',
          overtimeSignOut: '30',
          visitVerification: '0',
          allowIps: '',
          remark: '',
        },
      },
      formDataValidate: {
        systemType: [
          {required: true, message: '系统分类不能为空', trigger: 'change'}
        ],
        appName: [
          {required: true, message: '系统名称不能为空', trigger: 'blur'}
        ],
        appCode: [
          {required: true, message: '系统编码不能为空', trigger: 'blur'}
        ],
      },
    }
  },
  created() {
    this.selectTree();
  },
  methods: {
    activeMenu(menu){
      this.controlForm.showMenu = menu;
    },
    async selectTree() {
      await this.$get('/auth/api/auth/pc/system/selectSystemTree', {

      }).then(res => {
        if (res.code == 200) {
          this.controlForm.systemTree = res.dataList
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    changeEndTime(time){
      this.formData.endTime = time;
    },
    saveData() {
      console.log(this.formData)
      if (this.controlForm.parentId.length > 0) {
        this.formData.parentId = this.controlForm.parentId[this.controlForm.parentId.length - 1];
      }
      this.$refs.form.validate((valid)=> {
            if (valid) {
              console.log(this.formData)
              console.log(this.$refs.LiefengUploadLogo.uploadList[0])
              this.formData.paraJson.systemLogo = this.$refs.LiefengUploadLogo.uploadList[0] ?  this.$refs.LiefengUploadLogo.uploadList[0].url : '';
              this.formData.paraJson.systemImage = this.$refs.LiefengUploadImage.uploadList[0] ?  this.$refs.LiefengUploadImage.uploadList[0].url : '';
              this.formData.paraJson.appName = this.formData.appName
              this.formData.paraJson.appCode = this.formData.appCode
              console.log(this.formData)
              this.$post('/auth/api/auth/pc/system/add',{
                ...this.formData,
                paraJson:JSON.stringify(this.formData.paraJson) 
              }).then(res=>{
                if(res.code == 200){
                  //刷新父系统
                  this.$emit('getFatherSystemList');
                  this.$Message.success({
                    content:'新增成功',
                    background:true
                  });
                  this.closeModal();
                  this.$emit('resetBtn')
                  this.$emit('getTreeLsit')
                  this.selectTree();
                }else{
                  this.$Message.error({
                    content:res.desc,
                    background:true
                  })
                  return
                }
              })
            }
          }
      );
    },
    closeModal(flag){
      //关闭弹窗
      this.controlForm.formFlag = flag;
      this.showImage = false;
      this.$nextTick(()=>{this.showImage = true;})
      //清空表单
      Object.assign(this.$data.formData, this.$options.data().formData);
      //重置表单字段Form
      this.$refs['form'].resetFields();
      
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.securityDiv {
  border-width:0px;
  margin-left: 20px;
  width:72px;
  height:24px;
  display:flex;
  font-family:'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight:650;
  font-style:normal;
  color:#10BC92;
}
.container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 123px);
  /deep/.menu {
    height: 100%;
    .ivu-menu {
      height: 100%;
      width: 220px !important;
    }
  }
  .content {
    overflow: auto;
    padding-top: 20px;
    flex: 1;
    height: 100%;
  }
}

// /deep/ #modal_contentarea{
//   overflow: inherit !important;
// }
</style>